import { z } from "zod";

import { getShopifyGidPrefix } from "@smartrr/shared/utils/ensureShopifyGid";
import { CommonApi } from "../common/api";

export namespace LoyaltyApi {
  export namespace Enum {
    export namespace Event {
      export const arr = ["ACCOUNT_CREATED", "BILLING_SUCCESS"] as const;
      export const schema = z.enum(arr);
      export type Type = z.infer<typeof schema>;
    }

    export namespace Discount {
      export const arr = ["fixed_amount", "percentage"] as const;
      export const schema = z.enum(arr);
      export type Type = z.infer<typeof schema>;
    }
  }

  export namespace Event {
    export const schema = z.object({
      type: Enum.Event.schema,
      pointsEarned: z.number().int().gt(0, "Points earned should be greater than 0"),
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace CurrencyMultiplier {
    export const schema = z.object({
      currency: CommonApi.CurrencyCodeSchema.schema,
      multiplier: z.number().positive(),
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace Tier {
    export const schema = z.object({
      id: z.string().optional(),
      tierName: z.string(),
      tierColor: z.string(),
      tierRank: z.number(),
      minimumPoints: z.number().nonnegative(),
      incentives: z.array(z.string()).nullable(),
      products: z.array(z.string()).nullable(),
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace RewardProduct {
    export const schema = z.object({
      rewardType: z.literal("PRODUCT"),
      id: z.string().uuid(),
      variantId: z.string().uuid(),
      shopifyId: z.string().startsWith(getShopifyGidPrefix("ProductVariant"), {
        message: "Shopify ID must be defined with prefix",
      }),
      costInPoints: z.number().int().gt(0, { message: "Please add value greater than zero" }),
      hidden: z.boolean(),
      subscriberOnly: z.boolean(),
      tiers: z.array(Tier.schema),
      variant: z.object({
        id: z.string().uuid(),
        shopifyId: z.string().startsWith(getShopifyGidPrefix("ProductVariant"), {
          message: "Shopify ID must be defined with prefix",
        }),
        productName: z.string().min(1, "Product name should be present"),
        variantName: z.string().min(1, "Variant name should be present"),
        variantImages: z.array(z.string().url()),
      }),
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace PriceRule {
    export const schema = z.object({
      id: z.string(),
      type: Enum.Discount.schema,
      value: z.string().refine(
        input => {
          const parsedInput = z.coerce.number().int().negative().safeParse(input);
          return parsedInput.success;
        },
        { message: "Discount must be a negative integer" }
      ),
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace Incentive {
    export const schema = z.object({
      rewardType: z.literal("INCENTIVE"),
      id: z.string(),
      color: z.string(),
      costInPoints: z.number().int().gt(0, { message: "Cost should be greater than 0" }),
      priceRule: PriceRule.schema.omit({
        id: true,
      }),
      hidden: z.boolean(),
      subscriberOnly: z.boolean(),
      tiers: z.array(z.string()),
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace VariantOrIncentive {
    export const schema = z.union([RewardProduct.schema, Incentive.schema]);
    export type Type = z.infer<typeof schema>;
  }

  export namespace Bonus {
    export const schema = z.object({
      every: z.number().int().gt(0, { message: "Every should be greater than 0" }),
      givePoints: z.number().int().gt(0, { message: "Points earned should be greater than 0" }),
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace Referral {
    export const schema = z.object({
      name: z
        .string()
        .min(1, "Name should have at least 1 character")
        .max(200, { message: "Name must have 200 characters or fewer" }),
      description: z
        .string()
        .min(1, "description should have at least 1 character")
        .max(200, { message: "Name must have 200 characters or fewer" }),
      pointsInReward: z.number().min(0, "Rewarded points must be greater than or equal to 0"),
      priceRule: PriceRule.schema,
      generatedDiscountCodes: z.array(z.string()),
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace Settings {
    export const schema = z.object({
      /**
       * Limits the number of times a specific reward can be applied to an upcoming order
       * 0 : No limits
       * 1+: Limited to a maximum of the specified amount.
       */
      limitUse: z.number().min(0, { message: "Limit use should be 0 or greater. 0 for no limit" }),
      /**
       * Decides if the discount codes generated for loyalty redeems can be stacked
       */
      stackableDiscountCodes: z.boolean(),
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace ReferralCode {
    export const schema = z.object({
      code: z.string(),
      link: z.string().url(),
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace Status {
    export const status = ["NOT_MIGRATED", "DISABLED", "ENABLED", "PREVIEW"] as const;
    export const schema = z.enum(status);
    export type Type = z.infer<typeof schema>;
  }

  export namespace Program {
    export const schema = z.object({
      id: z.string().uuid(),
      status: Status.schema,
      otpEnabled: z.boolean(),
      tiersEnabled: z.boolean(),
      updatedDate: z.string().datetime(),
      organizationId: z.string().uuid(),
      description: z.string().max(150, { message: "Description cannot be longer than 150 characters." }),
      rewardUnitNameSingular: z.string().min(1, "Please add a singular name of the unit"),
      rewardUnitNamePlural: z.string().min(1, "Please add a plural name of the unit"),
      events: z.array(Event.schema).min(1, "At least one event required"),
      currencies: z.array(CurrencyMultiplier.schema),
      variants: z.array(RewardProduct.schema),
      incentives: z.array(Incentive.schema),
      bonus: Bonus.schema.nullable(),
      referralProgram: Referral.schema.nullable(),
      rewardSettings: Settings.schema,
      tiers: z.array(Tier.schema),
      activeSubscriberMultiplier: z
        .number()
        .gte(1)
        .multipleOf(0.1, {
          message: "Multiplier can be up to one decimal point",
        })
        .nullable(),
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace CheckoutInfo {
    export const schema = z.object({
      otpEnabled: Program.schema.shape.otpEnabled,
      events: Program.schema.shape.events,
    });
    export type Type = z.infer<typeof schema>;
  }

  export namespace Analytics {
    // TODO: Update schema to accommodate 'tier' analytics alongside 'general' analytics when the time comes
    export const schema = z.object({
      pointsInCirculation: z.number().nonnegative(),
      pointsRedeemed: z.object({
        sumInterval: z.number(), // the sum in the current interval
        percentageDifference: z.number(), // the different between current and before
      }),
      pointsAwarded: z.object({
        // same as the redeemed
        sumInterval: z.number(),
        percentageDifference: z.number(),
      }),
    });
    export type Type = z.infer<typeof schema>;
  }
}

export const LoyaltyStatus = LoyaltyApi.Status.schema.Values;
